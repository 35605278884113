import React from 'react';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { ButtonBase } from '@mui/material';

export const EscalaHeader = () => {
  const location = useLocation();
  const match = location.pathname.match(/^\/escala\/([^/]*)\/?([^/]*)\/?$/);
  const pageId = match ? match[1] : null;

  return (
    <div className="w-full border-b border-b-gray3/30 mt-4 flex gap-3">
      <ButtonHeader
        selected={!pageId || pageId === 'gestor'}
        onClick={() => navigate('/escala/gestor')}
      >
        Escalas
      </ButtonHeader>
      <ButtonHeader
        selected={pageId === 'calendario'}
        onClick={() => navigate('/escala/calendario')}
      >
        Calendário
      </ButtonHeader>
      <ButtonHeader
        selected={pageId === 'relatorios'}
        onClick={() => navigate('/escala/relatorios')}
      >
        Relatórios
      </ButtonHeader>
      <ButtonHeader
        selected={pageId === 'equipe'}
        onClick={() => navigate('/escala/equipe')}
      >
        Equipe médica
      </ButtonHeader>
    </div>
  );
};

const ButtonHeader = ({ children, selected, ...props }) => {
  return (
    <ButtonBase
      className={`
      text-sm font-bold pb-1 border-b-4 border-solid 
      ${selected ? 'text-gray4  border-gray4 ' : 'text-gray4/40 border-transparent'}
    `}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};
