import React from 'react';

import loadable from '@loadable/component';

import { IsGestor } from '@components/auth';
import { Layout } from '@components/layout';
import { EscalaHeader } from '@screens/escala/components/EscalaHeader';

const PlantoesMedicos = loadable(
  () => import('@screens/escala/relatorios/components/PlantoesMedicos')
);

const Index = (props) => {
  return (
    <IsGestor>
      <Layout {...props}>
        <div className="w-full h-full flex flex-col">
          <EscalaHeader />
          <PlantoesMedicos />
        </div>
      </Layout>
    </IsGestor>
  );
};

export default Index;
