import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';

import { CircularProgress, Typography, Container } from '@mui/material';

import { getAuth, signInWithCustomToken } from 'firebase/auth';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useAuth } from '@context';

import { SimpleButton } from '../form';
import { FullContainer, Flex } from '../layout';
import { useDevice, supportUrl } from '../utils';

function Protected({ children, uri }) {
  const authCtx = useAuth();
  const { isDesktop } = useDevice();
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [loggingFromToken, setLoggingFromToken] = useState(true);

  useEffect(() => {
    // Verifica se há token na URL para login customizado
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      const auth = getAuth();
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          // Login com custom token realizado com sucesso.
          // Opcional: remover o parâmetro token da URL após o login bem-sucedido.
          params.delete('token');
          window.history.replaceState(
            {},
            '',
            `${window.location.pathname}?${params.toString()}`
          );

          setLoggingFromToken(false);
        })
        .catch((error) => {
          console.error('Erro ao logar com token customizado:', error);
          // Opcional: redirecionar para uma página de erro ou notificar o usuário
          setLoggingFromToken(false);
          navigate('/login');
        });
    } else {
      setLoggingFromToken(false);
    }
  }, []);

  useEffect(() => {
    if (loggingFromToken) return;

    if (!authCtx.user && !authCtx.loadingUser) {
      navigate('/login');
      return;
    }

    if (
      authCtx.user &&
      !authCtx.user.emailVerified &&
      uri !== '/unverified_email'
    ) {
      navigate('/unverified_email');
      return;
    }
  }, [authCtx?.user, authCtx?.loadingUser, uri, loggingFromToken]);

  if (authCtx?.user && authCtx?.userInfo) {
    return children;
  }

  return (
    <FullContainer sx={{ backgroundColor: 'var(--gray-4)' }}>
      {loadingUserInfo ? (
        <Flex>
          <CircularProgress />
        </Flex>
      ) : (
        <Container maxWidth="md">
          <Typography
            variant="h5"
            sx={{ color: 'white', textAlign: 'center', textWrap: 'balance' }}
          >
            Ocorreu um erro no seu cadastro. Por favor, entre em contato com
            nossa equipe de suporte
          </Typography>
          <SimpleButton
            sx={{ mt: '2rem', gap: '1rem' }}
            href={supportUrl(isDesktop())}
            component="a"
            target="_blank"
            rel="noopener noreferer"
          >
            <WhatsAppIcon /> suporte
          </SimpleButton>
        </Container>
      )}
    </FullContainer>
  );
}

export { Protected };
